
/***************************************************
  Imports
***************************************************/

import React from "react"

import { useRecoilValue } from "recoil"
import { linkedSubPagesState } from "../state"

import Layout from "../components/Layout"
import PageNumber from "../components/PageNumber"
import DropDowmMenu from "../components/DropDownMenu"

/***************************************************
  Component
***************************************************/

export default ({
  linkSubPageGroupID,
  title,
  description,
  subTitle,
  summaryInfo,
  titleImg,
  videos,
  documents,
}) => {
  const subPageGroup = useRecoilValue(linkedSubPagesState(linkSubPageGroupID))

  const documentsUI = () => {
    return (
      <section className="section-documents">
        <PageNumber
          className="col-3 span-11 dark"
          number={videos ? "02" : "01"}
          text="DOCUMENTS"
        />
        <div className="col-5 span-6 icons">
          {documents.map((doc, index) => {
            return (
              <a href={doc.link} target="_blank">
                <div key={index}>
                  <img src={doc.icon} alt={doc.title} />
                  <span>{doc.title}</span>
                </div>
              </a>
            )
          })}
        </div>
      </section>
    )
  }

  const videosUI = () => {
    return (
      <section className="section-videos">
        <PageNumber className="col-3 span-11 dark" number="01" text="VIDEOS" />
        {videos.map((vid, index) => {
          return (
            <div
              className={`${
                index % 2 == 0 ? "col-4" : "col-9 second-column"
              } span-4`}
              key={index}
            >
              <h2>{vid.title}</h2>
              <div className="video-container">{vid.embedCode}</div>
            </div>
          )
        })}
      </section>
    )
  }

  return (
    <Layout
      headerTheme="dark"
      className="page-type-i"
      title={title}
      description={description}
    >
      <section className="section-hero">
        <div className="col-4 span-6">
          {!subPageGroup ? (
            <h1>{title}</h1>
          ) : (
            <DropDowmMenu
              title={subPageGroup.title}
              options={subPageGroup.options}
              active={title}
            />
          )}
          {subTitle && <h2>{subTitle}</h2>}
        </div>
      </section>

      {summaryInfo && (
        <section className="section-hero-summary">
          <div className="col-4 span-10">{summaryInfo()}</div>
        </section>
      )}

      {titleImg && (
        <section className="section-hero-image">
          <img className="col-4 span-8" src={titleImg} alt={title} />
        </section>
      )}

      {videos && videosUI()}

      {documents && documentsUI()}
    </Layout>
  )
}
